import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

const ThemeContext = React.createContext({
  theme: "dark",
  toggleTheme: () => {},
})

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("dark")

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light"
    setTheme(newTheme)
    localStorage.setItem("theme", JSON.stringify(newTheme))
  }

  useEffect(() => {
    const supportsDarkMode =
      window.matchMedia("(prefers-color-scheme: dark)").matches === true
    const preference = JSON.parse(localStorage.getItem("theme"))
    if (preference && preference.length) {
      setTheme(preference)
    } else if (supportsDarkMode) {
      setTheme("dark")
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ThemeContext
export { ThemeProvider }
