import React from "react"
import PropTypes from "prop-types"
import "typeface-fira-sans"
import "typeface-playfair-display"
import "typeface-roboto-slab"

import { ThemeProvider } from "./src/context/theme-context"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired
}
